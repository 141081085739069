<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <a href="/">
      <img alt="Logo" :src="require('@/assets/images/logo.svg')" height="38" />
    </a>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <!-- <button
        class="btn p-0 burger-icon ml-4"
        id="kt_header_mobile_toggle"
        ref="kt_header_mobile_toggle"
      >
        <span></span>
      </button> -->
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <!-- <button
        class="btn btn-hover-text-primary p-0 ml-2"
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
      >
        <span class="svg-icon svg-icon-xl">
          <inline-svg class="svg-icon" src="media/svg/icons/General/User.svg" />
        </span>
      </button> -->
      <!--end::Topbar Mobile Toggle-->
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="custom-v-dropdown"
        no-caret
        right
        no-flip
        text="Actions"
        v-b-tooltip.hover="'View Profile'"
      >
        <template v-slot:button-content>
          <button
            class="btn btn-hover-text-primary p-0 ml-2"
            id="kt_header_mobile_topbar_toggle"
            ref="kt_header_mobile_topbar_toggle"
          >
            <span class="svg-icon svg-icon-xl">
              <inline-svg
                class="svg-icon"
                :src="`${publicPath}media/svg/icons/General/User.svg`"
              />
            </span>
          </button>
        </template>
        <!--begin::Navigation-->
        <div class="navi navi-hover min-w-md-250px">
          <!-- <b-dropdown-text tag="div" text-class="px-0">
            <span class="navi-link px-4 py-2 d-block">
              <span class="navi-icon mr-1">
                <span class="svg-icon svg-icon-lg svg-icon-primary">
                  <inline-svg
                    src="media/svg/icons/Communication/Mail-notification.svg"
                  />
                </span>
              </span>
              <span class="navi-text text-muted text-hover-primary">
                test.gmail.com
              </span>
            </span></b-dropdown-text
          > -->
          <b-dropdown-text tag="div" class="navi-item" text-class="px-0">
            <a href="#" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon-logout"></i>
              </span>
              <span class="navi-text" @click.prevent="onLogout">Sign Out</span>
            </a>
          </b-dropdown-text>
        </div>
        <!--end::Navigation-->
      </b-dropdown>
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "KTHeaderMobile",
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  // mounted() {
  //   // Init Header Topbar For Mobile Mode
  //   KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  // },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch("auth/logOut")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
};
</script>
