<template>
  <div
    class="subheader py-2 py-lg-4 subheader-solid"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ title }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li
              class="breadcrumb-item"
              v-if="!breadcrumb.hideLastLabel"
              :key="`${i}-${breadcrumb.id}`"
            >
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class="text-muted"
                :key="i"
                v-if="!breadcrumb.route && !breadcrumb.hideLastLabel"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="d-none d-lg-flex align-items-center">
        <!-- <a href="#" class="btn btn-light font-weight-bold btn-sm"> Actions </a> -->

        <!-- <b-dropdown
          size="sm"
          variant="link"
          toggle-class="custom-v-dropdown"
          no-caret
          right
          no-flip
          text="Actions"
          v-b-tooltip.hover="'View Profile'"
        >
          <template v-slot:button-content>
            <div
              class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 text-decoration-none"
              id="kt_quick_user_toggle"
            >
              <span
                class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
              >
                Hi,
              </span>
              <span
                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
              >
                User Name
              </span>
              <span class="symbol symbol-35 symbol-light-success">
                <img
                  v-if="false"
                  alt="Pic"
                  :src="currentUserPersonalInfo.photo"
                />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  U
                </span>
              </span>
            </div>
          </template>
          <div class="navi navi-hover min-w-md-250px">
            <b-dropdown-text tag="div" text-class="px-0">
              <span class="navi-link px-4 py-2 d-block">
                <span class="navi-icon mr-1">
                  <span class="svg-icon svg-icon-lg svg-icon-primary">
                    <inline-svg
                      src="media/svg/icons/Communication/Mail-notification.svg"
                    />

                  </span>
                </span>
                <span class="navi-text text-muted text-hover-primary">
                  test.gmail.com
                </span>
              </span></b-dropdown-text
            >
            <b-dropdown-text tag="div" class="navi-item" text-class="px-0">
              <a href="#" class="navi-link">
                <span class="navi-icon">
                  <i class="flaticon-logout"></i>
                </span>
                <span class="navi-text" @click="onLogout">Sign Out</span>
              </a>
            </b-dropdown-text>
          </div>
        </b-dropdown> -->
        <!-- .navi .navi-item .navi-link -->
        <div class="navi">
          <div class="navi-item">
            <a href="#" class="navi-link" @click.prevent="onLogout">
              <span class="navi-icon">
                <i class="flaticon-logout"></i>
              </span>
              <span class="navi-text text-nowrap">Sign Out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  computed: {
    ...mapGetters({
      layoutConfig: "layoutConfig",
    }),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch("auth/logOut")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
};
</script>
